import React from 'react';
import { Helmet } from "react-helmet";
import { Button } from '@mui/material';
import '../scss/pages/user-style.scss';
import Header from '../Components/common/Header';
import { toAbsoluteUrl } from '../utils';


const TITLE = "Donate2Good :: Signup Success"


const LoginSuccess = (props) => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section className="user-sec">
        <Header />
        <div className="user-inner-sec submitted-success">
          <div className="submitted-img">
            <img src={toAbsoluteUrl("/images/steps-submition.svg")} alt="close" />
          </div>
          <div className="submitted-content">
            <h3 className="submitted-title">Thanks for your submission!</h3>
            <p>We will be in touch</p>
          </div>
          <div className="user-footer">
            <Button className="user-btn" onClick={() => {
              const link = document.createElement('a');
              link.href = 'https://donate2good.com';
              link.click()
            }}>Done</Button>
          </div>
        </div>
      </section>
    </>
  )
}
export default LoginSuccess;