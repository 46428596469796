import * as ACTION_TYPES from './Types'

const initialState = {
    message: '',
    error: null,
    setart: 0,
    length: 10,
    region: []
}

export const regionStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_REGION_SUCCESS:
            return {
                ...state,
                error: null,
                region: action.payload.data
            }
        case ACTION_TYPES.GET_REGION_ERROR:
            return {
                ...state,
                region: [],
                error: action.payload
            }
        default:
            return state
    }
}