import axios from 'axios'
import { API_BASE } from '../../Constant/api'
import * as ACTION_TYPES from './Types'

export const getRegion = () => dispatch => {
    return axios.get(`${API_BASE}auth/getRegionList`)
        .then(res => {
            if (res.status === 200) {

                dispatch({ type: ACTION_TYPES.GET_REGION_SUCCESS, payload: res.data })
                return res.data

            } else {
                dispatch({ type: ACTION_TYPES.GET_REGION_ERROR, payload: res.data })
                return res.data
            }
        }).catch(err => {
            dispatch({ type: ACTION_TYPES.GET_REGION_ERROR, payload: err.response })
            throw err
        })
}