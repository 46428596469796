import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../Constant/api'

export const organizationLoginAction = (data) => dispatch => {
    // dispatch({ type: ACTION_TYPES.ORGANIZATION_LOGIN_REQUEST })
    return axios.post(`${API_BASE}auth/login`, data)
        .then(res => {
            if (res.status === 200) {
                // set access token for rest of the api
                axios.defaults.headers.common['Authorization'] = res.data.data.accessToken;
                dispatch({ type: ACTION_TYPES.ORGANIZATION_LOGIN_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.ORGANIZATION_LOGIN_ERROR, payload: res.data })
                return res.data

            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.ORGANIZATION_LOGIN_ERROR, payload: err.response })
            throw err
        })
}

export const logoutAction = (data) => dispatch => {
    // dispatch({ type: ACTION_TYPES.ORGANIZATION_LOGIN_REQUEST })
    return axios.post(`${API_BASE}api/logout`, data)
        .then(res => {
            if (res.status === 200) {
                localStorage.removeItem("webToken")
                localStorage.removeItem("user")
                return res.data
            } else {
                return res.data

            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.ORGANIZATION_LOGIN_ERROR, payload: err.response })
            throw err
        })
}

export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}

export const forgotPass = data => dispatch => {
    return axios
        .post(`${API_BASE}auth/forgotPassword`, data)
        .then(res => {
            dispatch({ type: ACTION_TYPES.FORGOT_PASSWORD_SUCCESS, payload: res.data })
            return res.data
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.FORGOT_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}