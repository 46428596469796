import React, { useState } from "react";
// import { DatePicker, LocalizationProvider } from "@mui/lab"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, FormControl, Select, MenuItem, FormHelperText, InputAdornment, IconButton } from "@mui/material"
// import SearchIcon from "@mui/icons-material/";
import NumberFormat from "react-number-format"
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import moment from "moment";

export const SimpleInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	return (
		<>
			<TextField {...input} id="outlined-error-helper-text" label={label} type={type} error={(touched && error) ? true : false} variant="filled" size="small" helperText={touched && error} />

		</>
	)
}

// export const TextAreaInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
//     return (
//         <>
//             <TextField {...input} id="outlined-error-helper-text" label={label} error={(touched && error) ? true : false} variant="filled" size="small" multiline rows={4} helperText={touched && error} className="inputCap"/>
//         </>
//     )
// }

export const EmailInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	return (
		<>
			<TextField {...input} id="outlined-error-helper-text" type={type} label={label} error={(touched && error) ? true : false} variant="filled" size="small" helperText={touched && error} />
			{/* <TextField {...input} id="outlined-error-helper-text" label={label} variant="filled" size="small"/> */}
			{/* <div className="text-error filled-message text-danger text-left">
                {error && touched && <span>{error}</span>}
            </div> */}
		</>
	)
}

export const NumberInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	return (
		<>
			<NumberFormat {...input} format="(###) ###-####" name={name} label={label} id="outlined-error-helper-text" size="small" error={(touched && error) ? true : false} helperText={touched && error} variant="filled" customInput={TextField} />
		</>
	)
}

export const CardInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	return (
		<>
			<NumberFormat {...input} format="#### #### #### ####" name={name} label={label} id="outlined-error-helper-text" size="small" error={(touched && error) ? true : false} helperText={touched && error} variant="filled" customInput={TextField} />
		</>
	)
}

export const CVVInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	return (
		<>
			<NumberFormat {...input} format="###" name={name} label={label} id="outlined-error-helper-text" size="small" error={(touched && error) ? true : false} helperText={touched && error} variant="filled" customInput={TextField} />
		</>
	)
}

export const ExpiryInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	return (
		<>
			<NumberFormat {...input} format="##/##" name={name} label={label} id="outlined-error-helper-text" size="small" error={(touched && error) ? true : false} helperText={touched && error} variant="filled" customInput={TextField} />
		</>
	)
}

export const InputPassword = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	const [state, setState] = useState(false)
	return (
		<>
			<TextField
				{...input}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={() => setState(!state)}>
								{state ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					),
				}}
				name={name}
				label={label}
				type={state ? 'text' : 'password'}
				id="outlined-error-helper-text"
				size="small" error={(touched && error) ? true : false}
				helperText={touched && error}
				variant="filled"
				customInput={TextField} />


		</>
	)
}

export const TextAreaInput = ({ input, label, disabled, name, type, meta: { touched, error, warning } }) => {
	return (
		<>
			<TextField {...input} id="outlined-error-helper-text" label={label} error={(touched && error) ? true : false} variant="filled" size="small" multiline rows={4} helperText={touched && error} className="inputCap" />
		</>
	)
}

export const RegionSelect = ({ input, label, disabled, name, type, region, meta: { touched, error, warning } }) => {
	return (
		<>
			<FormControl>
				<Select
					variant='filled'
					size='small'
					{...input}
					onChange={input.onChange}
					// onSelect={input.onChange}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
				>
					<MenuItem value="">
						Select {label}
					</MenuItem>
					{region?.length > 0 && region?.map((r, k) => {
						return (
							<MenuItem key={k} value={r._id}>{r.name}</MenuItem>
						)
					})}
					{/* <MenuItem value={20}>Twenty</MenuItem>
					<MenuItem value={30}>Thirty</MenuItem> */}
				</Select>
				{touched && error && (
					<FormHelperText style={{ fontSize: "15px" }} error>{error}</FormHelperText>
				)}
			</FormControl>

		</>
	)
}

export const DateInput = ({ input, label, disabled, name, type, setError, meta: { touched, error, warning } }) => {
	let [date, setDate] = useState(null)
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					{...input}
					minDate={moment().add(2, 'days').toDate()}
					label="Pick up date"
					value={date}
					onChange={(newValue) => { input.onChange(newValue); setError(''); setDate(newValue) }}
					renderInput={(params) => <TextField id="filled-basic" variant="filled" size="small" {...params} />} />
				{error && (
					<p style={{ color: 'red', marginBottom: '5px', marginTop: '5px' }}>{error}</p>
				)}
			</LocalizationProvider>
		</>
	)
}


export const TimeInput = ({ input, defaultValue, label, disabled, flag, name, type, setError, meta: { touched, error, warning } }) => {
	var minTime = new Date();
	minTime.setHours(10);
	minTime.setMinutes(0);
	minTime.setMilliseconds(0);

	var maxTime = new Date();
	maxTime.setHours(22);
	maxTime.setMinutes(0);
	maxTime.setMilliseconds(0);
	return (
		<>
			<TextField
				id="time"
				name={name}
				label={flag ? "Pick up timeframe start" : "Pick up timeframe end"}
				type="time"
				InputLabelProps={{
					shrink: true,
				}}
				onChange={(newValue) => { input.onChange(newValue); setError('') }}
				inputProps={{
					step: 300, // 5 min
				}}
				min={moment(minTime).format('HH:MM')}
				max={moment(maxTime).format('HH:MM')}
				variant="filled"
				sx={{ width: 150 }}
				{...input}
			/>
			{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
			{/* <TimePicker  {...input} label={flag ? "Pick up timeframe start" : "Pick up timeframe end"} onChange={(newValue) => { input.onChange(newValue); setError('') }} renderInput={(params) => <TextField type='time' className='time-picker' id="filled-basic" variant="filled" size="small" {...params} />} /> */}
			{/* <TextField
				type='time'
				InputLabelProps={{
					shrink: true,
				}}
				name={name}
				label={flag ? "Pick up timeframe start" : "Pick up timeframe end"}
				defaultValue="07:30"
				className='time-picker'
				id="time"
				variant="filled"
				size="small" {...input} /> */}
			{/* </LocalizationProvider> */}
		</>
	)
}

