import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../Constant/api'

export const organizationSignupAction = (data) => dispatch => {
    dispatch({ type: ACTION_TYPES.ORGANIZATION_SIGNUP_REQUEST })
    return axios.post(`${API_BASE}auth/organizationSignup`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.ORGANIZATION_SIGNUP_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.ORGANIZATION_SIGNUP_ERROR, payload: res.data })
                return res.data

            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.ORGANIZATION_SIGNUP_ERROR, payload: err.response })
            throw err
        })
}