import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Button } from '@mui/material';
import { reduxForm, Field } from 'redux-form'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Components/common/Header';
import { moverSignupAction } from '../store/MoverSignup/Actions';
import { NumberInput, RegionSelect, SimpleInput } from '../Components/FormFields/FormFields';
import { moverSignup as validate } from '../Components/FormFields/validation';
// import RegionPicker from '../Components/GoogleAddressPicker/RegionPicker';
import Loader from '../Components/common/Loader';
import '../scss/pages/user-style.scss';
import { toast } from 'react-toastify';
import { getRegion } from '../store/Region/Actions';


const TITLE = "Donate2Good :: Signup"


let MoversLogin = (props) => {
  const history = useHistory();
  const { handleSubmit, submitting } = props
  const { region } = useSelector(state => state.region);

  const [isLoading, setIsLoading] = useState(false)
  // const [lat, setlat] = useState('')
  // const [lng, setlng] = useState('')
  // const [city, setCity] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    const abortcontroller = new AbortController()
    dispatch(getRegion())
    return () => {
      abortcontroller.abort()
    }
    // eslint-disable-next-line
  }, [])

  const handleFormSubmit = (values) => {
    setIsLoading(true)
    values.phoneNumber = values.phoneNumber.replace(/\D+/g, "");
    // const sendData = { ...values, latitude: lat, longitude: lng, city: city || '' };
    const sendData = { ...values };
    dispatch(moverSignupAction(sendData))
      .then(res => {
        if (res.status === 200) {
          setIsLoading(false)
          toast.success(res.message)
          history.push('/signup-success')
        } else {
          toast.error(res.message)
          setIsLoading(false)
        }
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err.response.data.message)
      });
    // console.log({ values });
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Loader loading={isLoading}>
        <section className="user-sec">
          <Header />
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="user-inner-sec">
              <div className="user-header">
                <h3 className="user-heading">Movers Application</h3>
              </div>
              <div className="user-form">
                <Field component={SimpleInput} type='text' label="First Name" name='firstName' />
                <Field component={SimpleInput} type='text' label="Last Name" name='lastName' />
                <Field component={NumberInput} label="Phone Number" name='phoneNumber' />
                <Field component={SimpleInput} type='email' label="Email Address" name='email' />
                <Field component={SimpleInput} type='text' label="Company Name" name='companyName' />
                <Field component={RegionSelect} region={region ?? region} label="Region" name='regionId' />
                {/* <Field component={RegionPicker} label="Address" name='address' setlat={setlat} setlng={setlng} setCity={setCity} /> */}
              </div>
              <div className="user-footer">
                <Button disabled={submitting} type='submit' className="user-btn">Submit</Button>
              </div>
            </div>
          </form>
        </section>
      </Loader>
    </>
  )
}

MoversLogin = reduxForm({
  form: "MoverSignup",
  validate
})(MoversLogin)

export default MoversLogin;