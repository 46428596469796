import React from 'react';

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const TermsCondition = React.lazy(() => import('./pages/TermsCondition'));
const Organizations = React.lazy(() => import('./pages/Organizations'));
const Movers = React.lazy(() => import('./pages/Movers'));
const Donation = React.lazy(() => import('./pages/Donation'));
const DonationSuccess = React.lazy(() => import('./Components/Donations/steps/FinalStep'));
const Login = React.lazy(() => import('./pages/Login'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const OrganizationsSignup = React.lazy(() => import('./pages/OrganizationsSignup'));
const MoversSignup = React.lazy(() => import('./pages/MoversSignup'));
// const PaymentMethod = React.lazy(() => import('./pages/PaymentMethod'));
// const Subscription = React.lazy(() => import('./pages/Subscription'));


const routes = [
    // Home Route
    { path: '/', exact: true, name: 'Home', component: Donation },
    { path: '/about', exact: true, name: 'About', component: About },
    { path: '/privacy-policy', exact: true, name: 'About', component: PrivacyPolicy },
    { path: '/terms-condition', exact: true, name: 'About', component: TermsCondition },
    { path: '/about', exact: true, name: 'About', component: About },
    { path: '/organizations', exact: true, name: 'Organizations', component: Organizations },
    { path: '/movers', exact: true, name: 'Movers', component: Movers },
    // { path: '/donate', exact: true, name: 'Donation', component: Home },
    { path: '/donate-success', exact: true, name: 'Donation Success', component: DonationSuccess },
    { path: '/mover_login', exact: true, name: 'Login', component: Login },
    { path: '/org_login', exact: true, name: 'Login', component: Login },
    { path: '/org_forgotpassword', exact: true, name: 'Login', component: ForgotPassword },
    { path: '/mover_forgotpassword', exact: true, name: 'Login', component: ForgotPassword },
    { path: '/organizations-signup', exact: true, name: 'Organizations Signup', component: OrganizationsSignup },
    { path: '/movers-signup', exact: true, name: 'Movers Signup', component: MoversSignup },
    // { path: '/payment-method', exact: true, name: 'PaymentMethod', component: PaymentMethod },
    // { path: '/subscription', exact: true, name: 'Subscription', component: Subscription },
]

export default routes