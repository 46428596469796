import * as ACTION_TYPES from './Types'

const initialState = {
    loading: false,
    data: {},
    region: [],
    error: null
}

export const moverReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.MOVER_SIGNUP_REQUEST:
        case ACTION_TYPES.REGION_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.MOVER_SIGNUP_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case ACTION_TYPES.REGION_LIST_SUCCESS:
            return {
                ...state,
                region: action.payload.data,
                loading: false
            }
        case ACTION_TYPES.MOVER_SIGNUP_ERROR:
            return {
                ...state,
                data: {},
                loading: false,
                error: action.payload
            }
        case ACTION_TYPES.REGION_LIST_ERROR:
            return {
                ...state,
                region: [],
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}