import moment from "moment"

export const organizationSignup = (value) => {
    let error = {}
    if (!value.name || !value.name.trim()) {
        error.name = "Please enter your name."
    }
    if (!value.email || !value.email.trim()) {
        error.email = "Please enter your email address."
    }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter a valid email address."
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) { error.phoneNumber = 'Please enter phone number' }
    else if (value.phoneNumber.replace(/\D+/g, "").trim().length !== 10) {
        error.phoneNumber = "Phone number must contain at last 10 digits."
    }
    if (!value.companyName || !value.companyName.trim()) {
        error.companyName = "Please enter your company name."
    } else if (value.companyName.length < 3) {
        error.companyName = "Company name must contain at last 2 characters.";
    }
    if (!value.storeName || !value.storeName.trim()) {
        error.storeName = "Please enter your store name."
    } else if (value.storeName.length < 3) {
        error.storeName = "Store name must contain at last 2 characters.";
    }

    // if (!value.address || !value.address.trim()) {
    //     error.address = "Please enter your address."
    // }
    if (!value.regionId) {
        error.regionId = "Please select region.";
    }
    return error
}

export const moverSignup = (value) => {
    let error = {}
    // if (!value.name || !value.name.trim()) {
    //     error.name = "Please enter your name."
    // }
    // First Name Validation
    if (!value.firstName || !value.firstName.trim()) {
        error.firstName = "Please enter first name";
    } else if (value.firstName.length < 3) {
        error.firstName = "First name must contain at least 2 characters.";
    }
    // else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(value.firstName)) {
    //     error.firstName =
    //         "First Name field should not be start with white space and accept only characters.";
    // }

    //Last Name validation
    if (!value.lastName || !value.lastName.trim()) {
        error.lastName = "Please enter last name";
    } else if (value.lastName.length < 3) {
        error.lastName = "Last name must contain at least 2 characters.";
    }
    // else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(value.lastName)) {
    //     error.lastName =
    //         "Last Name field should not be start with white space and accept only characters.";
    // }

    if (!value.email || !value.email.trim()) {
        error.email = "Please enter your email address."
    }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter a valid email address."
    }

    if (!value.phoneNumber || !value.phoneNumber.trim()) { error.phoneNumber = 'Please enter phone number' }
    else if (value.phoneNumber.replace(/\D+/g, "").trim().length !== 10) {
        error.phoneNumber = "Phone number must contain at last 10 digits."
    }

    if (!value.companyName || !value.companyName.trim()) {
        error.companyName = "Please enter your company name."
    }
    // if (!value.address || !value.address.trim()) {
    //     error.address = "Please enter your address."
    // }
    if (!value.regionId) {
        error.regionId = "Please select region.";
    }
    // if (!value.region || !value.region.trim()) {
    //     error.region = "Please enter your address."
    // }

    return error
}


export const newDonation = (values) => {
    const error = {};

    //First Name Validation
    if (!values.firstName || !values.firstName.trim()) {
        error.firstName = "Please enter first name";
    } else if (values.firstName.length < 3) {
        error.firstName = "First name must contain at least 2 characters.";
    }
    // else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(values.firstName)) {
    //     error.firstName =
    //         "Firs tName field should not be start with white space and accept only characters.";
    // }

    //Last Name Validation
    if (!values.lastName || !values.lastName.trim()) {
        error.lastName = "Please enter last name";
    } else if (values.lastName.length < 3) {
        error.lastName = "Last name must contain at least 2 characters.";
    }
    // else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(values.lastName)) {
    //     error.lastName =
    //         "Last Name field should not be start with white space and accept only characters.";
    // }

    //phoneNumber Validation
    if (!values.phoneNumber || !values.phoneNumber.trim()) {
        error.phoneNumber = "Please enter phone number";
    }
    else if (values.phoneNumber.replace(/\D+/g, "").trim().length !== 10) {
        error.phoneNumber = "Phone number must contain at last 10 digits."
    }


    //Email Validation
    if (!values.email) {
        error.email = "Please enter email address.";
    } else if (
        !/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(
            values.email
        )
    ) {
        error.email = "Please enter valid email address.";
    }

    // description validation
    if (!values.description || !values.description.trim()) {
        error.description = "Please enter description";
    }
    // else if (values.description.length < 6 || values.description.length > 150) {
    //     error.description = "Description must be between 6 to 150 characters.";
    // }

    return error;
};

export const donationLocation = (values) => {
    const error = {};

    if (!values.address || !values.address.trim()) {
        error.address = "Please enter location.";
    }

    return error;
}

export const regionSelectValidation = (values) => {
    const error = {}
    if (!values.regionId) {
        error.regionId = "Please select region.";
    }
    return error
}

export const paymentValidation = (value) => {
    let error = {};
    if (!value.name || !value.name.trim()) { error.name = 'Please enter card holder name.' }

    if (!value.cardNumber || !value.cardNumber.trim()) { error.cardNumber = 'Please enter your card number.' }
    else if (value.cardNumber.split(' ').join('').trim().length !== 16) { error.cardNumber = 'Please enter valid your card number.' }

    if (!value.expiry || !value.expiry.trim()) { error.expiry = 'Please enter expiry date of your card.' }
    else if (value?.expiry?.split('/').join('')?.trim().length !== 4) { error.expiry = 'Please enter valid expiry date of your card.' }

    if (!value.cvc || !value.cvc.trim()) { error.cvc = "Please enter cvv number." }
    else if (value.cvc.trim().length !== 3) { error.cvc = "Please enter valid cvv number." }

    return error;
}

export const login = (value) => {
    let error = {};

    if (!value.email || !value.email.trim()) {
        error.email = "Please enter your email address."
    }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter a valid email address."
    }

    if (!value.password || !value.password.trim()) {
        error.password = "Please enter password";
    }
    else if (value.password.length < 6) {
        error.password = "Password must contain at last 6 characters."
    }

    return error;
}

export const pickupValidation = (value) => {
    let error = {};
    console.log("Validate");
    if (!value.pickUpDate) {
        error.pickUpDate = "Please select pickup date."
    } 
    // else if (moment(value?.optionalDate).isSame(moment(value?.pickUpDate))) {
    //     error.optionalDate = "Secondary date should be greater then preferred date."
    // }
    if (!value.timeslotId) {
        error.timeslotId = "Please select time slot.";
    }
    if (!value.optionalDate) {
        error.optionalDate = "Please select pickup date."
    }
    //  else if (moment(value?.optionalDate).isSame(moment(value?.pickUpDate))) {
    //     error.optionalDate = "Secondary date should be greater then preferred date."
    // } 
    else if (moment(value?.optionalDate).isBefore(moment(value?.pickUpDate))) {
        error.optionalDate = "Secondary date should be greater then preferred date."
    } /* else if (moment(value?.optionalDate).format("l") == moment(value?.pickUpDate).format("l")) {
        error.optionalDate = "Secondary date can not be same as preferred date."
    } */
    if (!value.optionalTimeSlotId) {
        error.optionalTimeSlotId = "Please select time slot.";
    }
    if ((value.optionalTimeSlotId == value.timeslotId) && (moment(value?.optionalDate).format("l") == moment(value?.pickUpDate).format("l"))) {
        error.optionalTimeSlotId = "Secondary date and time slot can not be same as preferred date."
    }
    // else if (moment(value?.optionalDate).isBefore(moment().add(1, 'days').toDate())) {
    //     error.optionalDate = "Please select valid date."
    // }
    // else if (moment(value?.pickUpDate).isBefore(moment().add(1, 'days').toDate())) {
    //     error.pickUpDate = "Please select valid date."
    // }
    return error;
}