import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../Constant/api'

export const moverSignupAction = (data) => dispatch => {
    dispatch({ type: ACTION_TYPES.MOVER_SIGNUP_REQUEST })
    return axios.post(`${API_BASE}auth/moverSignup`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.MOVER_SIGNUP_SUCCESS, payload: res.data })
                return res.data
            } else {
                dispatch({ type: ACTION_TYPES.MOVER_SIGNUP_ERROR, payload: res.data })
                return res.data
            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.MOVER_SIGNUP_ERROR, payload: err.response })
            throw err
        })
}

export const getRegionList = () => dispatch => {
    dispatch({ type: ACTION_TYPES.REGION_LIST_REQUEST })
    return axios.get(`${API_BASE}auth/getRegionList`)
        .then(res => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.REGION_LIST_SUCCESS, payload: res.data })
            } else {
                dispatch({ type: ACTION_TYPES.REGION_LIST_ERROR, payload: res.data })

            }
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.REGION_LIST_ERROR, payload: err.response })
        })
}