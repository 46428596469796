import React from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../utils';
import '../scss/pages/invoice.scss';


const Invoice = () => {
  return (
    <>
      <section className='invoice-sec'>
        <div className='invoice-container'>
          <div className='invoice-header'>
            <div className='logo-box'>
              <Link className='d-flex-center-start' to="/">
                <img src={toAbsoluteUrl("/images/logo_Fill.png")} alt="Logo Img" />
                <h1>Donate 2 Good</h1>
              </Link>
            </div>
            <div className='invoice-thanks'>
              <h2>Thank You!</h2>
            </div>
          </div>
          <div className='invoice-content'>
            <h3 className='invoice-heading'>Order Invoice</h3>
            <ul>
              <li><b>Date:</b> &#123;Date&#125;</li>
              <li><b>Billed to: </b>
                <p>&#123;Organization Name&#125;</p>
                <p>&#123;Organization Address&#125;</p>
              </li>
            </ul>
          </div>
          <div className='invoice-table-footer'>
            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Trip Distance</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Description">Description</td>
                  <td data-label="Trip Distance">&#123;Distance&#125;</td>
                  {/* <td data-label="Total">$&#123;Confirmed Offer amount&#125;</td> */}
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className='foot-heading'>Total</td>
                  {/* <td colspan="2" data-label="Total" className='foot-totle'>$&#123;Confirmed Offer amount&#125;</td> */}
                </tr>
              </tfoot>
            </table>
          </div>
          <div className='invoice-footer'>
            <div className='logo-box'>
              <Link className='d-flex-center-start' to="/">
                <img src={toAbsoluteUrl("/images/logo_Fill.png")} alt="Logo Img" />
                <h1>Donate 2 Good</h1>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Invoice;