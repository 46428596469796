import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./store/index";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from './Components/layout/index';
import OrganizationsSignup from "./pages/OrganizationsSignup";
import LoginSuccess from "./pages/LoginSuccess";
import Invoice from "./pages/Invoice";
import checkAuth from './authentication/Auth'
import MoversSignup from "./pages/MoversSignup";
import { ToastContainer } from "react-toastify";
import './middleware'
import 'react-toastify/dist/ReactToastify.css';
import { authenticate } from "./store/Login/Actions";
import { AUTHENTICATE_FAILED } from "./store/Login/Types";
import Login from "./pages/Login";
const Subscription = React.lazy(() => import('./pages/Subscription'));
const PaymentMethod = React.lazy(() => import('./pages/PaymentMethod'));


const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const token = JSON.parse(localStorage.getItem("webToken"));
const loggedInUser = JSON.parse(localStorage.getItem("user"));

// axiosInterceptor();

if (token && loggedInUser) {
  store.dispatch(authenticate(loggedInUser));
} else {
  store.dispatch({ type: AUTHENTICATE_FAILED });
}


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <React.Suspense fallback={loading()}>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
            />
            <Switch>
              {/* <Route exact path="/organizations-signup" name="OrganizationsLogin" component={OrganizationsSignup} />
              <Route exact path="/movers-signup" name="MoversLogin" component={MoversSignup} /> */}
              <Route exact path="/signup-success" name="LoginSuccess" component={LoginSuccess} />
              <Route exact path="/redirect_login" name="LoginSuccess" component={Login} />
              <Route exact path="/invoice" name="Invoice" component={Invoice} />
              <Route exact path="/mover_subscription" name="Subscription" component={checkAuth(Subscription)} />
              <Route exact path="/org_subscription" name="Subscription" component={checkAuth(Subscription)} />
              <Route exact path="/org_add_card" name="Add Card" component={checkAuth(PaymentMethod)} />
              <Route exact path="/mover_add_card" name="Add Card" component={checkAuth(PaymentMethod)} />
              <Route path="/" component={(Layout)} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
