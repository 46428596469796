import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Button } from '@mui/material';
import { reduxForm, Field } from 'redux-form'
import Header from '../Components/common/Header';
import { NumberInput, RegionSelect, SimpleInput } from '../Components/FormFields/FormFields';
import { organizationSignup as validate } from '../Components/FormFields/validation'
import { useDispatch, useSelector } from 'react-redux';
import { organizationSignupAction } from '../store/OrganizationSignup/Actions';
import RegionPicker from '../Components/GoogleAddressPicker/RegionPicker';
import Loader from '../Components/common/Loader';
import '../scss/pages/user-style.scss';
import { toast } from 'react-toastify';
import { getRegion } from '../store/Region/Actions';


const TITLE = "Donate2Good :: Signup"


let OrganizationsSignup = (props) => {
  const { handleSubmit, submitting } = props
  const dispatch = useDispatch()
  const { region } = useSelector(state => state.region);

  const [isLoading, setIsLoading] = useState(false)
  const [lat, setlat] = useState('')
  const [lng, setlng] = useState('')
  const [city, setCity] = useState('')

  useEffect(() => {
    const abortcontroller = new AbortController()
    dispatch(getRegion())
    return () => {
      abortcontroller.abort()
    }
    // eslint-disable-next-line
  }, [])

  const handleFormSubmit = (values) => {
    setIsLoading(true)
    values.phoneNumber = values.phoneNumber.replace(/\D+/g, "")
    const sendData = { ...values, latitude: lat, longitude: lng, city: city }
    // const sendData = { ...values }
    dispatch(organizationSignupAction(sendData))
      .then(res => {
        toast.success(res.message)
        setIsLoading(false)
        props.history.push('/signup-success')
      }).catch(err => {
        setIsLoading(false)
        toast.error(err.response.data.message)
        // console.log("err in organization", err);
      })
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Loader loading={isLoading}>
        <section className="user-sec">
          <Header />
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="user-inner-sec">
              <div className="user-header">
                <h3 className="user-heading">Organization Application</h3>
              </div>
              <div className="user-form-org">
                <Field component={SimpleInput} type='text' label="Store Name" name='storeName' />
                <Field component={SimpleInput} type='text' label="Organization Name" name='companyName' />
                <Field component={SimpleInput} type='text' label="Contact Name" name='name' />
                <Field component={NumberInput} label="Phone Number" name='phoneNumber' />
                <Field component={SimpleInput} type='email' label="Email Address" name='email' />
                <Field component={RegionSelect} region={region ?? region} label="Region" name='regionId' />
                <Field component={RegionPicker} label="Office Address" name='address' setlat={setlat} setlng={setlng} setCity={setCity} />
              </div>
              <div className="user-footer">
                <Button disabled={submitting} type='submit' className="user-btn">Next</Button>
              </div>
            </div>
          </form>
        </section>
      </Loader>
    </>
  )
}

OrganizationsSignup = reduxForm({
  form: "OrganizationSignup",
  validate
})(OrganizationsSignup)

export default OrganizationsSignup;