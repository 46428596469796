import React, { useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../utils/index';
import { useLocation } from 'react-router-dom'
import Headroom from 'react-headroom';


const Header = () => {

  const navbarLinks = useRef();
  const handleNavbarButton = (e) => {
    navbarLinks.current.classList.toggle('menu-collapse');
  };

  const location = useLocation()
  const addClass = location?.pathname === '/' ? "" : "home-header"
  // console.log(location);


  return (
    <>
      <Headroom
      >
        <header className={`app-header d-flex flex-wrap ${addClass}`}>
          <div className="container-fluid">
            <div className="row d-flex-center-between">
              <div className="logo-box">
                <a href='https://donate2good.com' rel="noopener noreferrer" className="d-flex-center-start"><img className="logo-image" src={toAbsoluteUrl("/images/White.svg")} alt="Logo Img" /><h1></h1></a>
              </div>
              <div className="header-menu d-flex-center-between">
                <nav className='navbar'>
                  {/* <button onClick={(e) => { handleNavbarButton(e); }} className='navbar-toggler'>
                    <span className='navbar-toggler-icon'><img src={toAbsoluteUrl("/images/menu-icon.svg")} alt="Logo Img" /></span>
                  </button> */}
                  {/* <div className="menubar">
                    <div ref={navbarLinks} className='navbar-links menu-collapse'>
                      <NavLink exact activeClassName='is-active' onClick={(e) => { handleNavbarButton(e); }} className="nav-link" to='/about'>About</NavLink>
                      <NavLink exact activeClassName='is-active' onClick={(e) => { handleNavbarButton(e); }} className="nav-link" to='/Organizations'>Organizations</NavLink>
                      <NavLink exact activeClassName='is-active' onClick={(e) => { handleNavbarButton(e); }} className="nav-link" to='/movers'>Movers</NavLink>
                    </div>
                  </div> */}
                </nav>
                {/* <div className="header-btn">
                  <Link to="/donate" className='donate-btn'>Donate</Link>
                </div> */}
              </div>
            </div>
          </div>
        </header>
      </Headroom>
    </>
  )
}
export default Header;