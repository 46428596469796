import React from 'react';
import { TextField } from '@mui/material'
import { usePlacesWidget } from "react-google-autocomplete";

const RegionPicker = ({ input, meta: { touched, region, error, warning }, label, setlat, setlng, setLocation, setCity }) => {
	console.log({ label });
	const { ref: materialRef } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE,
		onPlaceSelected: (place) => {
			const [cityObj] = place?.address_components?.filter((add) => add.types.includes("locality"))

			if (place.geometry) {
				if (setlat) {
					setlat(place.geometry.location.lat())
				}
				if (setlng) {
					setlng(place.geometry.location.lng())
				}
				if (setCity) {
					setCity(cityObj?.long_name)
				}
				if (setLocation) {
					setLocation([{
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng()
					}])
				}
			}
			input.onChange(place.formatted_address)
		},
		onChange: (value) => { input.onChange(value) },
		options: {
			types: [],
			componentRestrictions: { country: "us" },
		},
	});
	return (
		<>
			<TextField className='form-address' {...input} placeholder={label ? label : "Office Address"} id="outlined-error-helper-text" error={(touched && error) ? true : false} variant="filled" size="small" helperText={touched && error} inputRef={materialRef} />
		</>
	);
};

export default RegionPicker;