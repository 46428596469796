import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import Loader from '../Components/common/Loader';
import { EmailInput, InputPassword } from '../Components/FormFields/FormFields';
import { login as validate } from '../Components/FormFields/validation';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { organizationLoginAction } from '../store/Login/Actions'
import { toast } from 'react-toastify';
import '../scss/pages/login.scss';
import { Helmet } from 'react-helmet';

const TITLE = "Donate2Good :: Login"

let Login = (props) => {
  const { handleSubmit, submitting } = props;

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const type = location?.pathname === '/mover_login' ? "2" : "1"

  const token = JSON.parse(localStorage.getItem("webToken"));
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  const handleFormSubmit = (values) => {
    setIsLoading(true)
    const sendData = { ...values, type }
    dispatch(organizationLoginAction(sendData)).then(res => {
      if (res.status === 200) {
        setIsLoading(false)
        toast.success(res.message)
        if (type === "2") {
          history.push('/mover_subscription')
        } else {
          history.push('/org_subscription')
        }
      } else {
        setIsLoading(false)
        toast.error(res.message)
      }
    })
      .catch(err => {
        setIsLoading(false)
        toast.error(err.response.data.message)
      })
  }

  if (token && loggedInUser) {
    if (type === "2") {
      history.push('/mover_subscription')
    } else {
      history.push('/org_subscription')
    }
  }

  const pathName = type === "1" ? '/org_forgotpassword' : '/mover_forgotpassword';

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Loader loading={isLoading}>
        <div className="login-sec">
          <div className="login-inner">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="login-form-inner">
                <h3 className="login-heading">Login</h3>
                <Field name="email" type="email" component={EmailInput} label="Email Address" />
                <Field name="password" type="password" component={InputPassword} label="Password" />
                <div className='forgot-link'>
                  <Link to={{ pathname: pathName }}>Forgot Password ?</Link>
                </div>
                <div className="login-footer">
                  <Button type="submit" disabled={submitting} className="step-btn">Next</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Loader>
    </>
  );
};

Login = reduxForm({
  form: "Login",
  validate,
})(Login);

export default Login;