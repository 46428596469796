import * as ACTION_TYPES from './Types'
const initialState = {
    heading: "New Donation",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    description: '',
    address: "",
    latitude: "",
    longitude: "",
    regionId: "",
    organizationId: "",
    // categoryId: "",
    // startTime: "",
    pickUpDate: "",
    timeslotId: "",
    optionalDate: "",
    optionalTimeSlotId: "",
    OnGroundFloor: '',
    uploadImages: [],
    blobImages: [],
    loading: false,
}

export const donerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ADD_DONATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.ADD_DONATION_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ACTION_TYPES.ADD_DONATION_ERROR:
            return {
                ...state,
                loading: false
            }
        case ACTION_TYPES.ADD_IMAGES_FILES:
            return {
                ...state,
                uploadImages: action.payload,
            }
        case "CHANGE_STATUS":
            return {
                ...state,
                heading: "Which Organization do you prefer to support with your Donation?"
            }
        case ACTION_TYPES.ADD_DESCRIPTIONS:
            return {
                ...state,
                description: action.payload,
                heading: "Donation Images"
            }
        case ACTION_TYPES.ADD_BLOB_IMAGES_FILES:
            return {
                ...state,
                blobImages: action.payload,
                heading: "Donation Images"
            }
        case ACTION_TYPES.ADD_PERSONAL_DETAILS:
            return {
                ...state,
                email: action.payload.email,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                phoneNumber: action.payload.phoneNumber,
                heading: "Donation Location"
            }

        case ACTION_TYPES.ADD_ADDRESS:
            return {
                ...state,
                address: action.payload.address,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
                heading: "Pick up Details"
            }

        case ACTION_TYPES.ADD_DATE_TIME:
            return {
                ...state,
                // startTime: action.payload.startTime,
                pickUpDate: action.payload.pickUpDate,
                timeslotId: action.payload.timeslotId,
                optionalDate: action.payload.optionalDate,
                optionalTimeSlotId: action.payload.optionalTimeSlotId,
                OnGroundFloor: action.payload.isLocatedOnGround,
                heading: "Donation Images"
            }

        case ACTION_TYPES.ADD_ORGANIZATION:
            return {
                ...state,
                organizationId: action.payload.organizationId,
                heading: "Which Organization do you prefer to support with your Donation?"
            }

        case ACTION_TYPES.ADD_REGION:
            return {
                ...state,
                regionId: action.payload.regionId,
                heading: "Where would you like your Donation picked-up from?"
            }

        case ACTION_TYPES.CLEAR_STORE:
            return {
                ...state,
                heading: "New Donation",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                description: '',
                address: "",
                latitude: "",
                longitude: "",
                regionId: "",
                organizationId: "",
                // categoryId: "",
                startTime: "",
                pickUpDate: "",
                endTime: "",
                uploadImages: [],
                blobImages: [],
                loading: false,
            }


        default:
            return state
    }
}