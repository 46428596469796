export const ADD_IMAGES_FILES = "ADD_IMAGES_FILES"
export const ADD_BLOB_IMAGES_FILES = "ADD_BLOB_IMAGES_FILES"
export const ADD_PERSONAL_DETAILS = "ADD_PERSONAL_DETAILS"
export const ADD_ADDRESS = "ADD_ADDRESS"
export const ADD_ORGANIZATION = "ADD_ORGANIZATION"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const ADD_DATE_TIME = "ADD_DATE_TIME"
export const ADD_REGION = "ADD_REGION"
export const ADD_DESCRIPTIONS = "ADD_DESCRIPTIONS"

export const ADD_DONATION_REQUEST = "ADD_DONATION_REQUEST"
export const ADD_DONATION_SUCCESS = "ADD_DONATION_SUCCESS"
export const ADD_DONATION_ERROR = "ADD_DONATION_ERROR"

export const CLEAR_STORE = "CLEAR_STORE"


