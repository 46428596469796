import React from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import routes from '../../routes';
import Loader from "../common/Loader";
import { useSelector } from "react-redux";

const Layout = props => {
    const { loading } = useSelector(state => state.doner)

    if (loading) {
        document.body.classList.add("stop-scrolling");
    } else {
        document.body.classList.remove("stop-scrolling");
    }

    return (
        <>
            <Loader loading={loading}>
                <Header />
                <main className="app-main d-flex flex-column">
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => (
                                        <route.component {...props} />
                                    )}
                                />
                            ) : (null)
                        })}
                        <Redirect to="/" />
                    </Switch>
                </main>
                <Footer />
            </Loader>
        </>
    )
}

export default Layout;