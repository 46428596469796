import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<>
			<footer className="app-footer d-flex flex-wrap">
				{/* <div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-2">
							<div className="footer-content">
								<ul>
									<li><Link to="/">Home</Link></li>
									<li><Link to="/about">About</Link></li>
									<li><Link to="/Organizations">Organizations</Link></li>
									<li><Link to="/movers">Movers</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-4">
							<div className="footer-content">
								<h3 className="footer-title">Get Started</h3>
								<Link to="/donate" className="whitebg-btn">Donate Now</Link>
							</div>
						</div>
						<div className="col-lg-3 col-md-2">
							<div className="footer-content legal">
								<h3 className="footer-title">Legal</h3>
								<ul>
									<li><Link to="/terms-condition">Terms of Service</Link></li>
									<li><Link to="/privacy-policy">Privacy Policy</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-4">
							<div className="footer-content signup">
								<Link to="/organizations-signup" className="border-btn">ORGANIZATION SIGN UP</Link>
								<Link to="/movers-signup" className="border-btn">MOVER SIGN UP</Link>
							</div>
						</div>
					</div>
				</div> */}
				<div className="copyright">
					<div className="container">
						<p>Copyright © 2022 Donate 2 Good, Inc. All Rights Reserved</p>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer;