export const ORGANIZATION_LOGIN_REQUEST = "ORGANIZATION_LOGIN_REQUEST"
export const ORGANIZATION_LOGIN_SUCCESS = "ORGANIZATION_LOGIN_SUCCESS"
export const ORGANIZATION_LOGIN_ERROR = "ORGANIZATION_LOGIN_ERROR"


//LOGOUT Actions
export const LOGOUT = "LOGOUT"

//AUTHENTICATE Actions
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED"

//Forgot Password Actions
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"