import * as ACTION_TYPES from './Types'

const initialState = {
    loading: false,
    data: {},
    error: null
}

export const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ORGANIZATION_SIGNUP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.ORGANIZATION_SIGNUP_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case ACTION_TYPES.ORGANIZATION_SIGNUP_ERROR:
            return {
                ...state,
                data: {},
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}