import * as ACTION_TYPES from './Types'

const initialState = {
    loading: false,
    data: {},
    // token: "",
    id: null,
    error: null
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ORGANIZATION_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.ORGANIZATION_LOGIN_SUCCESS:
            localStorage.setItem('webToken', JSON.stringify(action.payload.data.accessToken));
            localStorage.setItem('user', JSON.stringify(action.payload.data));
            return {
                ...state,
                data: action.payload,
                // token: action.payload.data.accessToken,
                id: action.payload.data._id,
                loading: false
            }
        case ACTION_TYPES.ORGANIZATION_LOGIN_ERROR:
            return {
                ...state,
                data: {},
                loading: false,
                token: "",
                id: null,
                error: action.payload
            }
        case ACTION_TYPES.LOGOUT:
        case ACTION_TYPES.AUTHENTICATE_FAILED:
            localStorage.removeItem("webToken");
            localStorage.removeItem("user");
            return {
                ...state,
                isLoggedIn: false,
                userData: {}
            }
        default:
            return state
    }
}