import React from 'react';
import LoadingOverlay from 'react-loading-overlay'
import ClipLoader from "react-spinners/ClipLoader";

let Loader = ({ loading, children }) => {
    return (
        <LoadingOverlay active={loading} spinner={<ClipLoader color={"#002A85"} loading={loading} size={50} />}>{children}</LoadingOverlay>
    )
}

export default Loader