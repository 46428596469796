import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// eslint-disable-next-line import/no-anonymous-default-export
export default (ComposedComponent) => {
  const Authentication = (props) => {
    const token = localStorage.getItem("webToken");
    useEffect(() => {
      if (!token) {
        props.history.push("/");
      }
      // eslint-disable-next-line
    }, [token]);

    return <ComposedComponent {...props} />;
  };

  return withRouter(connect()(Authentication));
};
