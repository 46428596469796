import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { donerReducer } from './Donaer/Reducer'
import { moverReducer } from './MoverSignup/Reducer'
import { organizationReducer } from './OrganizationSignup/Reducer'
import { regionStateReducer } from './Region/Reducer'
import { loginReducer } from './Login/Reducer'

const rootReducer = combineReducers({
    form: formReducer,
    mover: moverReducer,
    organization: organizationReducer,
    region: regionStateReducer,
    doner: donerReducer,
    login: loginReducer,
})

export default rootReducer